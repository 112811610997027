import { Injectable, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})

export class ConstantsService {

  readonly ConstatntPartnerID: number = 50;
  // readonly ConstatntWebAPI: string = 'https://localhost:44372/api';
  // readonly ConstatntWebSiteUrl: string = 'https://localhost:44372/api';
  // readonly ConstatntWebAPI: string = 'https://api-mandaibirdparadise-com-staging.digiphotoglobal.com/api'
  //  readonly ConstatntWebSiteUrl: string = 'https://www-mandaibirdparadise-com-staging.digiphotoglobal.com/';//prod
   readonly ConstatntWebAPI: string = 'https://api.mandaimoment.mandai.com/api'
   readonly ConstatntWebSiteUrl: string = 'https://www.mandaimoment.mandai.com/';//prod
  //readonly ConstatntWebAPI: string = 'https://api-ibaa-staging.digiphotoglobal.com/api'

  // readonly ConstatntWebAPI: string = 'https://api-burjkhalifamoments-development.digiphotoglobal.com/api';
  //readonly ConstatntWebAPI: string = 'https://atlantisbahamservicebeta.mydeievents.com/api';  //stag

  //readonly ConstatntWebAPI: string = 'https://wbsyncservicepullbeta.mydeievents.com/api';  //stag
  //readonly ConstatntWebAPI: string = 'https://www-burjkhalifamoments-com.azurewebsites.net/webapp/api';  //stag
  
  
  //readonly ConstatntWebAPI: string = 'https://api.atlantisparadisephototreasures.com/api'; //prod
  //readonly ConstatntWebSiteUrl: string = 'https://atlantisbahamsbeta.mydeievents.com/';//stag
  // readonly ConstatntWebSiteUrl: string = 'https://www-mandaibirdparadise-com-staging.digiphotoglobal.com/';//prod

  
  userActivity;
  userInactive: Subject<any> = new Subject();
  constructor(protected _router: Router,
    private toastr: ToastrService, private http: HttpClient) { }

  loading(value: boolean) {
    
    var element = document.getElementById('loader');    
    if (value) {
      element.classList.add("myLoaderClass");
    }
    else {
      element.classList.remove("myLoaderClass");
    }
  }
  ///Session expired///
  setTimeout() {
    clearTimeout(this.userActivity);
    this.userActivity = setTimeout(() => {
      this.userInactive.next(undefined);
      sessionStorage.clear();
      alert('User session time out. Kindly re-login.');
    }, 1800000);
  }

  /// End of Session expired///
  getCountryCodes() {
    return new Promise((resolve, reject) => {
      $.getJSON("./../../assets/countries.json", (data) => {
        resolve(data); 
      }, (err) => {
        reject(err);
      })
    })
  }

  CheckUserImages(partnerUserID) {

    if ((sessionStorage.getItem('PartnerUserId') != null)) {
      //if ((sessionStorage.getItem('DefaultTheme') == null || (sessionStorage.getItem('DefaultTheme'))=='null')) {
      //  this._router.navigateByUrl('/location');
      //}
      //else {
        this.loading(true);
        return this.http.get(this.ConstatntWebAPI + '/Claim/'
        /*+sessionStorage.getItem('DefaultTheme')+'/'*/ + partnerUserID).subscribe(
          (res) => {            
            if (res == "ClaimActiveBoth") {
              sessionStorage.setItem("TabStatus", "ClaimActiveBoth")
              this._router.navigateByUrl('/claimed-photos');
            }
            else if (res == "ClaimActiveClaim") {
              sessionStorage.setItem("TabStatus", "ClaimActiveClaim")
              this._router.navigateByUrl('/claimed-photos');
            }
            else if (res == "NonClaimActiveNonClaim") {
              sessionStorage.setItem("TabStatus", "NonClaimActiveNonClaim")
              this._router.navigateByUrl('/nonclaimed-photos');
            }
            //else if (res == "location") {
            //  sessionStorage.setItem("DefaultTheme", null);
            //  this.toastr.warning('Invalid Venue');
            //}
            else if (res == "config") {
              //sessionStorage.setItem("DefaultTheme", null);
              this.toastr.warning('Invalid Venue configuration');
            }
            else {
              this._router.navigateByUrl('/qrclaim');
            }
            this.loading(false);
          });
      //}

    }
    else {
      this._router.navigateByUrl('/welcome');
    }
  }

  setPageTheme()
  {    
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove("theme-default","theme-aquarium", "theme-kidzania", "theme-icerink");

    if ((sessionStorage.getItem('DefaultTheme') == null)) {
     
      body.classList.add('theme-default');      
    }
    else  if ((sessionStorage.getItem('DefaultTheme') == 'aquarium')) {
      
      body.classList.add('theme-aquarium');    
      sessionStorage.setItem("LastDefaultTheme", 'theme-aquarium');
    }
    else  if ((sessionStorage.getItem('DefaultTheme') == 'kidzania')) {
      
      body.classList.add('theme-kidzania');
      sessionStorage.setItem("LastDefaultTheme", 'theme-kidzania');
    }
    else  if ((sessionStorage.getItem('DefaultTheme') == 'icerink')) {
      
      body.classList.add('theme-icerink');
      sessionStorage.setItem("LastDefaultTheme", 'theme-icerink');
    }
    else
    {      
      body.classList.add('theme-default');      
    }
        
  }

  setPageThemeFromPhotos()
  {    
    
    const body = document.getElementsByTagName('body')[0];
    if ((sessionStorage.getItem('DefaultTheme') == null)) {
     
      body.classList.remove("theme-default","theme-aquarium", "theme-kidzania", "theme-icerink");
      body.classList.add((sessionStorage.getItem('LastDefaultTheme')));
      if ((sessionStorage.getItem('LastDefaultTheme') == 'theme-aquarium')) {
        sessionStorage.setItem("DefaultTheme", 'aquarium');
      }
      else  if ((sessionStorage.getItem('LastDefaultTheme') == 'theme-kidzania')) {
        sessionStorage.setItem("DefaultTheme", 'kidzania');
      }
      else  if ((sessionStorage.getItem('LastDefaultTheme') == 'theme-icerink')) {
        sessionStorage.setItem("DefaultTheme", 'icerink');        
      }          
    }
    else  if ((sessionStorage.getItem('DefaultTheme') == 'aquarium')) {
      //do nothing
    }
    else  if ((sessionStorage.getItem('DefaultTheme') == 'kidzania')) {
      //do nothing          
    }
    else  if ((sessionStorage.getItem('DefaultTheme') == 'icerink')) {
      //do nothing   
    }
    else
    {            
      body.classList.remove("theme-default","theme-aquarium", "theme-kidzania", "theme-icerink");
      body.classList.add((sessionStorage.getItem('LastDefaultTheme')));
      if ((sessionStorage.getItem('LastDefaultTheme') == 'theme-aquarium')) {
        sessionStorage.setItem("DefaultTheme", 'aquarium');
      }
      else  if ((sessionStorage.getItem('LastDefaultTheme') == 'theme-kidzania')) {
        sessionStorage.setItem("DefaultTheme", 'kidzania');
      }
      else  if ((sessionStorage.getItem('LastDefaultTheme') == 'theme-icerink')) {
        sessionStorage.setItem("DefaultTheme", 'icerink');        
      } 
    }
        
  }

}
